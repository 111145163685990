import moment from "moment";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import uuid from "uuid";
import { IMAGES } from "../../constant";
import * as commonActions from "../../page/common/redux/actions";
import * as actions from "../../page/event/redux/actions";
import { getOrganization } from "../../utils";
import { ModalComponent, ToastNotificationSuccess } from "../common";
import LinkModal from "../common/linkModal";
import SharingHub from "../common/sharingHub";
import EmailModal from "../hotel/emailModal";
import VisibleByItinerary from "./VisibleByItinerary";
import ActivityForm from "./activityform";
import Itineraries from "./itineraries";
import { handleCloseEmail } from "../../utils/closeMailModal";

const ItinerariesContainers = (props) => {
  const loading = useSelector((state) => state.event.loading);
  const event = useSelector((state) => state.event.event);
  const events = useSelector((state) => state.calendar.calendarEvents);
  const templates = useSelector((state) => state.event.templates);
  const schedulesMasters = useSelector((state) => state.event.schedulesMasters);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    moveModal: false,
    mailingList: false,
    exportModal: false,
    activityForm: false,
    visibleByModal: false,
    labelModal: false,
    eventsSelected: [],
    showShare: false,
    sharingContent: "",
  });

  const [exportType, setExportType] = useState("email");

  const toggleEvent = (event) => {
    let events = state.eventsSelected;
    if (events.filter((s) => s.GUID === event.GUID).length > 0) {
      let filtered = events.filter((s) => s.GUID !== event.GUID);
      return setState((prev) => ({ ...prev, eventsSelected: filtered }));
    } else {
      let newOnes = events.concat(event);
      return setState((prev) => ({ ...prev, eventsSelected: newOnes }));
    }
  };

  const emptySelecteds = () => {
    setState((prev) => ({ ...prev, eventsSelected: [] }));
  };

  const toggleMailingList = () => {
    if (state.mailingList)
      return setState((prev) => ({ ...prev, mailingList: !prev.mailingList }));

    // Swal.fire({
    //   text: "How would you like to send this email?",
    //   imageUrl: IMAGES.LOGO_BLACK_P,
    //   imageWidth: 80,
    //   imageHeight: 80,
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "secondary",
    //   confirmButtonText: "Generate PDF",
    //   cancelButtonText: "As Body of Email",
    // }).then((result) => {
    //   if (result.value) {
    //     setExportType("export");
    //     setState((prev) => ({ ...prev, mailingList: !prev.mailingList }));
    //   } else if (result.dismiss === Swal.DismissReason.cancel) {
    setExportType("email");
    setState((prev) => ({ ...prev, mailingList: !prev.mailingList }));
    //   }
    // });
  };

  const toggleExportModal = () => {
    setState((prev) => ({ ...prev, exportModal: !prev.exportModal }));
  };

  const toggleActivityForm = () => {
    setState((prev) => ({ ...prev, activityForm: !prev.activityForm }));
  };

  const toggleVisibleBy = () => {
    setState((prev) => ({
      ...prev,
      visibleByModal: !prev.visibleByModal,
    }));
  };

  const toggleShare = (itinerary) => {
    let data = itinerary;
    let dataContent = "";
    if (data !== null) {
      let msg =
        data.Name + " \n" + moment(data.Date).format("MMM DD, YYYY") + "\n";
      let content = "";
      content += msg + "\n";
      let activities = data?.Schedules?.filter((x) => x.Deleted === null);
      activities?.forEach((item) => {
        let description = item?.Notes ? "(" + item?.Notes + ")" : "";
        let formatedTime =
          moment(item?.Date).format("hh:mm A") +
          (item?.DateUntil && item?.Date !== item?.DateUntil
            ? " - " + moment(item?.DateUntil).format("hh:mm A")
            : "");
        content += formatedTime + ": " + item?.Name + description + "\n";
      });
      dataContent = content;
    }

    setState((prev) => ({
      ...prev,
      showShare: !prev.showShare,
      sharingContent: dataContent,
    }));
  };

  const addItinerary = (itinerary, close) => {
    const { addItinerary, handleSidebarItem } = props;
    let newRecord = {
      ...itinerary,
      GUID: uuid.v4(),
      EventGUID: event.GUID,
      Public: true,
    };
    dispatch(
      addItinerary(
        newRecord,
        event.GUID,
        () => {
          ToastNotificationSuccess(1, "Itinerary sucessfully created");
          handleSidebarItem("ACTIVITIES", { ...newRecord, Schedules: [] });
        },
        () => {
          close(itinerary);
        }
      )
    );
  };

  const deleteItinerary = (itinerary, alreadyAsk) => {
    const { deleteItinerary, handleSidebarItem, scheduleMaster } = props;
    let activeItineraries = schedulesMasters.filter(
      (itinerary) => itinerary.GUID !== scheduleMaster.GUID
    )[0];
    if (alreadyAsk) return dispatch(deleteItinerary(itinerary, () => {}));
    Swal.fire({
      text: "Are you sure you want to delete '" + itinerary.Name + "'?",
      imageUrl: IMAGES.LOGO_BLACK_P,
      imageWidth: 80,
      imageHeight: 80,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        dispatch(
          deleteItinerary(itinerary, () => {
            ToastNotificationSuccess(1, "Itinerary sucessfully deleted");
            if (activeItineraries) {
              handleSidebarItem("ACTIVITIES", activeItineraries);
            } else {
              handleSidebarItem("EVENT_DETAILS", {});
            }
          })
        );
      }
    });
  };

  const editItinerary = (itinerary) => {
    const { handleSidebarItem } = props;
    dispatch(
      actions.editItinerary(
        itinerary,
        () => {
          ToastNotificationSuccess(1, "Itinerary succesfully updated");
          handleSidebarItem("ACTIVITIES", itinerary);
        },
        () => handleSidebarItem("ACTIVITIES", itinerary)
      )
    );
  };

  const createTemplate = (itinerary, label, callBack) => {
    dispatch(
      actions.createTemplate(
        itinerary.GUID,
        getOrganization().GUID,
        label,
        () => {
          ToastNotificationSuccess(1, "Itinerary saved as template");
          callBack();
        }
      )
    );
  };

  const createFromTemplate = (itinerary, toggle) => {
    dispatch(
      actions.createFromTemplate(itinerary.GUID, event.GUID, () => {
        ToastNotificationSuccess(1, "Itinerary created");
        toggle();
      })
    );
    history.go(`/calendar/${event.GUID}`);
  };

  // useEffect(() => {
  //   const currentData = schedulesMasters?.filter(data => data.GUID === sideBarItemData.GUID)[0]
  //   if (location.hash === "#itineraries") {
  //     if (schedulesMasters.length > 0) {
  //       // console.log({ itineararyLocationEffect: location, schedulesMasters });
  //       // console.log({schedulesInList: schedulesMasters})
  //       handleSidebarItem("ACTIVITIES", currentData);
  //     } else {
  //       handleSidebarItem("EVENT_DETAILS", {});
  //     }
  //   }
  // }, [location, schedulesMasters]);

  const { handleSidebarItem, sideBarItemData, scheduleMaster } = props;
  const { mailingList, activityForm, visibleByModal } = state;

  const [showTemplateLabel, setShowTemplateLabel] = useState(false);

  const handleAddEditTemplateLabel = () => {
    setShowTemplateLabel((prev) => !prev);
  };

  const handleCreateTemplate = (itinerary, label) => {
    createTemplate(itinerary, label, handleAddEditTemplateLabel);
  };

  return (
    <>
      <ModalComponent
        modal={visibleByModal}
        toggle={() => toggleVisibleBy()}
        childeren={
          <VisibleByItinerary
            scheduleMaster={sideBarItemData}
            toggle={() => toggleVisibleBy()}
          />
        }
      />

      <ModalComponent
        modal={state.showShare}
        toggle={toggleShare}
        childeren={
          <SharingHub
            ViaEmail
            // ViaLink
            ViaClipboard
            Item={state.sharingContent}
            toggle={toggleShare}
            //    ParentSectionGUID={attachment.EventGUID}

            Type="ITINERARIES"
            // sectionGUID={attachment.GUID}
          />
        }
      />

      <ModalComponent
        modal={mailingList}
        toggle={() => handleCloseEmail(toggleMailingList)}
        childeren={
          <EmailModal
            fromSection="ITINERARIES"
            ExportType={exportType}
            sendEmail={commonActions.sendEmail}
            itinerary={sideBarItemData}
            toggle={() => toggleMailingList()}
          />
        }
      />
      <ModalComponent
        clear={true}
        modal={activityForm}
        toggle={() => toggleActivityForm()}
        childeren={
          <ActivityForm
            edit={false}
            toggle={() => toggleActivityForm()}
            addActivity={actions.addActivity}
            scheduleMaster={sideBarItemData}
            event={event.GUID}
          />
        }
      />
      <ModalComponent
        toggle={toggleExportModal}
        modal={state.exportModal}
        childeren={
          <LinkModal
            title={
              <FormattedMessage
                id="copyAnotherEvents"
                defaultMessage="Copy to another events"
              />
            }
            footer={"Export"}
            type={"itineraries"}
            arrayEvents={events}
            setParentState={setState}
            toggle={toggleExportModal}
            toggleEvent={toggleEvent}
            eventsSelected={state.eventsSelected}
            sideBarItemData={sideBarItemData}
            actionResource={commonActions.exportResource}
            event={event}
            loading={loading}
            success={() => {
              ToastNotificationSuccess(1, "Itinerary exported successfully");
              emptySelecteds();
              toggleExportModal();
            }}
          />
        }
      />
      <Itineraries
        event={event}
        deleteItinerary={deleteItinerary}
        addItinerary={addItinerary}
        addActivity={actions.addActivity}
        toggleExportModal={toggleExportModal}
        toggleMailingList={toggleMailingList}
        toggleActivityForm={toggleActivityForm}
        schedulesMasters={schedulesMasters}
        scheduleMaster={scheduleMaster && scheduleMaster.GUID}
        handleSidebarItem={handleSidebarItem}
        sideBarItemData={sideBarItemData}
        editItinerary={editItinerary}
        toggleVisibleBy={toggleVisibleBy}
        createTemplate={createTemplate}
        createFromTemplate={createFromTemplate}
        templates={templates}
        loading={loading}
        toggleShare={toggleShare}
        actionResource={commonActions.exportResource}
        showTemplateLabel={showTemplateLabel}
        handleCreateTemplate={handleCreateTemplate}
        handleAddEditTemplateLabel={handleAddEditTemplateLabel}
      />
    </>
  );
};

export default ItinerariesContainers;
